import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PostBody from "../components/postbody"
import PageNav from "../components/pagenav"

export default ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  return (
    <Layout location={location}>
      <PostBody post={post} />
      <PageNav pageContext={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        subject
        author
        featimg {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
