import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import _ from "lodash"

import style from "./article.module.css"

const PostBody = ({ post }) => {
  return (
    <article className={style.single}>
      {post.frontmatter.featimg && (
        <figure className={style.featimg}>
          <Img
            fluid={post.frontmatter.featimg.childImageSharp.fluid}
            alt={post.frontmatter.title}
          />
        </figure>
      )}

      <h1 className={style.article__title}>{post.frontmatter.title}</h1>

      <div className={style.article__meta}>
        by {post.frontmatter.author}. Published{" "}
        {new Date(post.frontmatter.date).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}{" "}
      </div>
      <div className={style.article__tax}>
        Filed under:{" "}
        {post.frontmatter.subject.map((subject, index) => [
          index > 0 && ", ",
          <Link key={index} to={`/subjects/${_.kebabCase(subject)}`}>
            {subject}
          </Link>,
        ])}
      </div>
      {post.excerpt && (
        <div
          className={style.article__content}
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        />
      )}
      {post.html && (
        <div
          className={style.article__content}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      )}
    </article>
  )
}

export default PostBody
