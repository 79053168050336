import React from "react"
import { Link } from "gatsby"

import style from "./pagenav.module.css"

const PageNav = ({ pageContext }) => {
  const { prev, next } = pageContext
  return (
    <nav className={style.pagenav}>
      <div className={style.pagenav__item}>
        {prev && (
          <Link to={prev.fields.slug} rel="prev">
            ← Previous Post
          </Link>
        )}
      </div>

      <div className={style.pagenav__item}>
        {next && (
          <Link to={next.fields.slug} rel="next">
            Next Post →
          </Link>
        )}
      </div>
    </nav>
  )
}

export default PageNav
